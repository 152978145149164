
import TransitionFadeHeight from '../transitions/Transition-fade-height.vue';
import FacebookShare from '@/components-ui/FacebookShare.vue';

export default {
  name: 'Article',
  components: {
    FacebookShare,
    TransitionFadeHeight,
  },
  computed: {
    coverImageClass() {
      return `article-cover ${this.article.contentIsVisible ? 'noBoxShadow' : 'boxShadow'}`;
    },
    articleClass() {
      return `article ${this.article.contentIsVisible ? 'boxShadow' : 'noBoxShadow'}`;
    },
  },
  props: ['article', 'frontendOrigin'],
  methods: {
    toggleArticleContent() {
      if (this.article.contentIsVisible) {
        const article = document.getElementById(this.article.id);
        article.scrollIntoView({ block: 'start', behavior: 'smooth' });
        setTimeout(() => {
          this.article.contentIsVisible = !this.article.contentIsVisible;
        }, 300);
      } else {
        this.article.contentIsVisible = !this.article.contentIsVisible;
      }
    },
  },
};
